import React from 'react';
import kstreet from './kstreet.png';
import poster from './poster-covid.png';
import notturno from './notturno-transparent.png';
import fringe from './fringe.png';
import './App.css';

var menuHidden = true;

var handleMenu = function(open) {
  menuHidden = !menuHidden;
	var m1 = document.getElementById('m1');
	var m2 = document.getElementById('m2');
	if (!open) {
		m1.setAttribute('y', '13.5');
		m2.setAttribute('y', '13.5');
		setTimeout(function() {
			m1.setAttribute('transform', 'rotate(45, 15, 15)');
			m2.setAttribute('transform', 'rotate(-45, 15, 15)');
		}, 200);
    document.getElementById("nav").style.right=0;
	}

	else {
		m1.setAttribute('transform', 'rotate(0, 15, 15)');
		m2.setAttribute('transform', 'rotate(0, 15, 15)');
		setTimeout(function() {
			m1.setAttribute('y', '9');
			m2.setAttribute('y', '18');
		}, 200);
    document.getElementById("nav").style.right='-40vw';
	}
}

function App() {
  return (
    <div>
      <div className="navbar">
        <div onClick={()=>{var elmnt=document.getElementById('top'); handleMenu(true); elmnt.scrollIntoView({behavior: 'smooth'});}} className="header">K Street</div>
        <div className="nav" id="nav">
          <div onClick={()=>{var elmnt=document.getElementById('about'); handleMenu(true); elmnt.scrollIntoView({behavior: 'smooth'});}} className="link">About</div>
          <div onClick={()=>{var elmnt=document.getElementById('music'); handleMenu(true); elmnt.scrollIntoView({behavior: 'smooth'});}} className="link">Music</div>
          <div onClick={()=>{var elmnt=document.getElementById('team'); handleMenu(true); elmnt.scrollIntoView({behavior: 'smooth'});}} className="link">Team</div>
          <div onClick={()=>{handleMenu(!menuHidden)}} className="menu">
            <svg id="menu" className="svg" height="30" width="30">
              <rect id="m1" className="menu-bar" y="9" width="30" height="3" fill="black"></rect>
              <rect id="m2" className="menu-bar" y="18" width="30" height="3" fill="black"></rect>
            </svg>
          </div>
        </div>
      </div>
      <div className="background"/>
      <img src={kstreet} className="streetsign"/>
      <div className="topContent" id="top">
        <div className="landing">
          <h1>Accepted to the 2020 Capital Fringe Festival!</h1>
          <img src={fringe}/>
          <h2>Festival postponed till further notice due to COVID-19.</h2>
          <h2 id="enterEmail">Enter your email to get updates!</h2>
          <form id="form" onSubmit={
            (e) => {
              e.preventDefault();
                if ((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/.test(e.target[0].value))){
                  document.getElementById("submit-form").style.color = "white";
                  document.getElementById("submit-form").style.background = "black";
                  fetch('https://script.google.com/macros/s/AKfycbx9x2s6_-NO99zJlTnLVN4OLhRuzNTw56kFQRdB8zQLDZpJFA3R/exec?input_email=' + e.target[0].value)
                  .then((response) => response.json())
                  .then((data) => {
                    document.getElementById("enterEmail").innerHTML="Success! We'll be in touch."
                    var email = document.getElementById("email");
                    var submit = document.getElementById("submit-form");
                    email.remove();
                    submit.remove();

                  })
                  .catch((error) => {alert(error)})
                }
                else {
                  alert("Please submit a valid email address.")
                }
            }
          }>
            <input type="text" id="email" name="email" placeholder="email"/>
            <button type="submit" id="submit-form">Submit</button>
          </form>
        </div>

      </div>
      <div className="gradient"/>
      <div className="about-content" id="about">
        <div className="content-inner">
          <h1>About</h1>
          <div className="content-left">
            <p>K Street follows a young woman from Iowa, fresh out of college, who comes to Washington DC to be a hill intern. As she learns about Capitol Hill, she rises through the ranks to become Chief of Staff for a Senator with promising political prospects. But her success forces her to choose between her values and the harsh political realities of DC.</p>
            <br/>
            <p>Cloaked in up-beat songs like “I’ve Got a Bill,” “Is this the Hill to Die On?,” “Too Much Sense,” and “The Longworth Cafeteria,” K Street lovingly ribs DC’s political culture while addressing the angst that many Hill-dwellers feel.</p>
            <br/>
            <p></p>
          </div>
          <img src={poster} className="poster"/>
        </div>
      </div>
      <div className="music-content" id="music">
        <div className="content-inner">
          <h1>Music</h1>
          <div className="content-left">
            <h2>Track List</h2>
            <li>The Center of it All</li>
            <li>I've Got a Bill</li>
            <li>Whirlwind of Time</li>
            <li>A Draft's All It'll Ever Be</li>
            <li>The Longworth Cafeteria</li>
            <li>Too Much Sense</li>
            <li>The Hearing</li>
            <li>Is This the Hill to Die On?</li>
            <li>K Street</li>
            <br/>
            <li>Getting Staffed</li>
            <li>Bills, Bills, Bills</li>
            <li>Strike and Replace</li>
            <li>Seductive Policy</li>
            <li>Just Maybe</li>
            <li>I've Got a Bill (reprise)</li>
            <li>A Draft's All It'll Ever Be (reprise)</li>
            <li>A Heavenly Farm</li>
            <li>K Street (reprise)</li>

          </div>
          <div className="music">
            <iframe style={{display: 'block', marginBottom: 30, float: 'right', width: '40vw'}} height="200px" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/882525571&color=%23407678&auto_play=false&hide_related=true&show_comments=false&show_user=false&show_reposts=false&show_teaser=true&visual=true"></iframe>
            <iframe style={{display: 'block', marginBottom: 30, float: 'right', width: '40vw'}} height="200px" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/882526042&color=%233b7679&auto_play=false&hide_related=true&show_comments=false&show_user=false&show_reposts=false&show_teaser=true&visual=true"></iframe>
          </div>
        </div>
      </div>
      <div className="content" id="team">
        <div className="content-inner">
          <h1>Team</h1>
          <div className="content-left">
            <p>K Street was written by and is being produced by Karl Notturno — a writer, composer, and entrepreneur living in Washington DC who has significant experience in politics, technology, and finance.</p>
            <br/>
            <p>Karl was a 2019 Publius Fellow at the Claremont Institute, is a professional fellow at the Abigail Adams Institute, and is a co-founder and board member at A Soldier's Home. Karl graduated from Yale University with degrees in philosophy and history.</p>
          </div>
        </div>
        <img className="team" src={notturno}/>
      </div>
    </div>
  );
}

export default App;
